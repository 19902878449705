import { Params } from '@angular/router';
import { FilterMetadata } from 'primeng/api';

/**
 * Утилита, которая нормализует параметры URL в данные фильтра
 * @param filter object
 */

export const mapQueryParamsToFilter = (queryParams: Params): any => {
  const typedFilter: { [key: string]: FilterMetadata | FilterMetadata[] | any } = {};
  const paramFilter = queryParams['filter'];
  if (paramFilter) {
    const untypedFilter = JSON.parse(paramFilter);
    if (untypedFilter) {
      Object.entries(untypedFilter).forEach(([fieldName, fieldFilters]) => {
        if (!Array.isArray(fieldFilters)) {
          typedFilter[fieldName] = fieldFilters;
        } else {
          fieldFilters.forEach(fieldFilter => {
            Object.entries(fieldFilter).forEach(([key, value]) => {
              if (key === 'matchMode') {
                if (typeof value === 'string' && value.startsWith('date')) {
                  fieldFilter['value'] = new Date(fieldFilter['value']);
                }

                if (typedFilter.hasOwnProperty(fieldName)) {
                  typedFilter[fieldName].push(fieldFilter);
                } else {
                  typedFilter[fieldName] = [fieldFilter];
                }
              }
            });
          });
        }
      });
    }
  }

  return typedFilter;
};
