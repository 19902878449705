import { FilterMetadata } from 'primeng/api';

// import { dateToString } from './dateToString';

/**
 * Утилита, которая преобразует фильтры таблицы в фильтры API
 *
 * @param filter фильтры таблицы
 */
export const mapColumnFilterToApiFilters = (filter?: {
  [key: string]: FilterMetadata | FilterMetadata[] | undefined;
}) => {
  const apiFilter: { [key: string]: FilterMetadata[] } = {};

  if (filter) {
    Object.entries(filter).forEach(([fieldName, fieldFilters]) => {
      if (Array.isArray(fieldFilters)) {
        fieldFilters.forEach(fieldFilter => {
          Object.entries(fieldFilter).forEach(([key, value]) => {
            if (key === 'value' && value !== null && value !== undefined) {
              if (value instanceof Date) {
                // fieldFilter.value = dateToString(value); // TODO: из-за этого возникает проблема с исчезновением значения в фильтре типа Date
              }

              if (apiFilter.hasOwnProperty(fieldName)) {
                apiFilter[fieldName].push(fieldFilter);
              } else {
                apiFilter[fieldName] = [fieldFilter];
              }
            }
          });
        });
      }
    });
  }

  return apiFilter;
};
