import { Params } from '@angular/router';
import { isEmptyObject } from './isEmptyObject';

/**
 * Утилита, которая нормализует данные фильтра в параметры URL
 * @param filter object
 */
export const mapFilterToQueryParams = (filter?: any): Params | undefined => {
  if (!filter || isEmptyObject(filter)) {
    return undefined;
  }
  const serializedFilter = JSON.stringify(filter);
  const queryParams: Params = { filter: serializedFilter };

  return queryParams;
};
