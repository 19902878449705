import { formatDate } from '@angular/common';

/**
 * Утилита, которая преобразует дату в строку.
 *
 * @param date дата
 */
export const dateToString = (date?: Date): string | undefined => {
  return date ? formatDate(date, 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ', 'en') : undefined;
};
