import { ngxPermissionsGuard } from 'ngx-permissions';

/**
 * Генерация гарда для закрытия роута пермишеном
 * @param permission разрешение
 * @param guards дополнительные гарды
 */
export const getPermissionGuardProps = (permission: string, ...guards: any[]) => ({
  canActivate: [ngxPermissionsGuard, ...guards],
  data: {
    permissions: {
      only: [permission],
      redirectTo: '404',
    },
  },
});
